import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import useSlot from '../../hooks/useSlots';
import { ClientFormUserRole, ClientFormUserRoleKeys, ClientFormUserRoleValues } from '../../models/ClientFormUserRoles';
import withSlot, { SlotDefinitions } from '../../wrappers/withSlot';
import Accordion, { useAccordionContext } from '../shared/accordion/Accordion';
import { useTranslation } from 'react-i18next';
import WarningInfoIcon from '../shared/icon/WarningInfoIcon';
import Tooltip from '../shared/Tooltip';
import { ClientFormTag, ClientFormUser } from '../../models/ClientFormUser';
import UserListRenderer from './UserListRenderer';
import { PeopleType } from '../../models/Distribution';
import { ImageSize, ProfileImageStack } from './ProfileImageStack';
import XIcon from '../shared/icon/XIcon';
import Checkbox, { SliderSize } from '../shared/form-control/Checkbox';
import { closestCenter, DndContext, DragEndEvent, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, SortableContext, sortableKeyboardCoordinates, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import DragHandleIcon from '../shared/icon/DragHandleIcon';
import { restrictToFirstScrollableAncestor, restrictToVerticalAxis } from '@dnd-kit/modifiers';
import UsersSelect from './UsersSelect';
import useFetchClientUsers from '../../hooks/useFetchClientUsers';
import { useFetchActiveClientGroups } from '../../hooks/useFetchActiveClientGroups';
import GroupAvatar from '../shared/profile-image/GroupAvatar';
import LanguageUtils from '../../utils/LanguageUtils';
import StringUtils from '../../utils/StringUtils';
import { ClientTagTypeKeys } from '../../models/ClientTag';
import SyncIcon from '../shared/icon/SyncIcon';
import { deDuplicate } from '../../utils/ListUtils';
import User from '../../models/User';
import ObjectUtils from '../../utils/ObjectUtils';

type Props = {
  role: ClientFormUserRoleValues;
  users: ClientFormUser[];
  tags: ClientFormTag[];
  onTagsChange?: (groups: ClientFormTag[]) => void;
  disabled?: boolean;
  warning?: string | null;
  enableReordering?: boolean;
  requiredToggle?: boolean;
  enableOrdering?: boolean;
  searchPhrase?: string;
  formSectionId?: string;
  formUsers: ClientFormUser[];
  ownerRequiresAction?: boolean;
  onUserRemove: (user: ClientFormUser) => void;
  onUserChange: (user: ClientFormUser) => void;
  onUpdateUsers: (users: ClientFormUser[], groups: ClientFormTag[]) => void;
  onUsersReorder?: (users: ClientFormUser[]) => void;
};

const ClientFormRoleGroup = withSlot<Props, SlotDefinitions<['empty', 'footer']>>((props) => {
  const {
    role,
    users,
    tags,
    disabled,
    warning,
    onUserRemove,
    onTagsChange,
    requiredToggle,
    onUserChange,
    enableOrdering,
    onUsersReorder,
    onUpdateUsers,
    searchPhrase = '',
    formSectionId,
    formUsers,
    ownerRequiresAction,
  } = props;
  const { t } = useTranslation(['common']);
  const [open, setOpen] = useState(true);
  const { data: clientUsers = [] } = useFetchClientUsers();
  const { data: clientGroups = [] } = useFetchActiveClientGroups();

  const onUserRemoveInternal = useCallback(
    (user: ClientFormUser) => {
      // If a user is removed, that group cannot be synced anymore
      let changed = false;
      const groups = tags.map((x) => {
        // If the removed user is part of the group
        if (!x.users?.some((y) => y.id === user.id)) return x;

        if (x.synchronized) {
          changed = true;
          x.synchronized = false;
        }

        return x;
      });

      if (changed) {
        onTagsChange?.(groups.map((x) => ({ ...x, role })));
      }

      onUserRemove(user);
    },
    [onTagsChange, onUserRemove, role, tags],
  );

  const onTagsChangeInternal = useCallback(
    (tags: ClientFormTag[]) => {
      // If a group is syned, then _all_ users within the group must be present
      const syncedGroups = tags.filter((x) => x.synchronized);
      const syncedGroupUsers = deDuplicate(
        syncedGroups.flatMap((x) => x.users ?? []),
        'id',
      );

      const missingUsers = syncedGroupUsers
        .filter((x) => !users.some((y) => y.id === x.id))
        .map(
          (x) =>
            ({
              ...x,
              role,
              formSectionId,
              requiresAction: role === ClientFormUserRole.Owner ? ownerRequiresAction : undefined,
              tagIds: syncedGroups.filter((t) => t.users?.some((u) => u.id === x.id)).map((x) => x.id),
            }) as ClientFormUser,
        );
      if (missingUsers.length > 0) {
        onUpdateUsers(users.concat(missingUsers), tags);
      }

      onTagsChange?.(tags.map((x) => ({ ...x, role })));
    },
    [formSectionId, onTagsChange, onUpdateUsers, ownerRequiresAction, role, users],
  );

  const onGroupRemoved = useCallback(
    (tag: ClientFormTag) => {
      const updatedUsers = ObjectUtils.DeepClone(users)
        .filter((x) => {
          if (x.addedAsIndividual) return true;
          if (x.tagIds?.includes(tag.id)) return false;

          return true;
        })
        .map((x) => {
          x.tagIds = x.tagIds?.filter((t) => t !== tag.id);
          return x;
        });

      onUpdateUsers(
        updatedUsers,
        tags.filter((x) => x.id !== tag.id),
      );
    },
    [onUpdateUsers, tags, users],
  );

  const onUserRequiredChanged = useCallback(
    (user: ClientFormUser, required: boolean) => {
      onUserChange({ ...user, requiresAction: required });
    },
    [onUserChange],
  );

  const onUpdateUsersInternal = useCallback(
    (users: User[], tags: ClientFormTag[]) => {
      const formUsers: ClientFormUser[] = users.map((x) => ({
        ...x,
        role,
        formSectionId,
        requiresAction: role === ClientFormUserRole.Owner ? ownerRequiresAction : undefined,
      }));
      const formTags = tags.map((x) => ({
        ...x,
        role,
        formSectionId: formSectionId ?? null,
      }));

      onUpdateUsers(formUsers, formTags);
    },
    [formSectionId, onUpdateUsers, ownerRequiresAction, role],
  );

  const emptySlot = useSlot(
    props,
    'empty',
    useMemo(() => <span>{t('permissions-modal.group.placeholders.unassigned')}</span>, [t]),
  );

  const footerSlot = useSlot(props, 'footer');

  const accordionTitle = useMemo(
    () => (
      <div className="flex max-h-10 w-full items-center justify-between overflow-hidden">
        <div className="flex items-center gap-2">
          {warning && (
            <Tooltip text={warning}>
              {(tooltip) => (
                <span {...tooltip}>
                  <WarningInfoIcon className="text-semantic-2 h-8 w-8" />
                </span>
              )}
            </Tooltip>
          )}
          <span className="text-black">{t(ClientFormUserRoleKeys[role])}</span>
          {!disabled && (
            <UsersSelect
              users={clientUsers}
              clientGroups={clientGroups}
              selectedTags={tags}
              selectedUsers={users as (User & { tagIds: string[] })[]}
              onUsersChanged={onUpdateUsersInternal}
              singleSelect={role === ClientFormUserRole.Owner}
              formUsers={formUsers}
            />
          )}
        </div>
        <div className="mr-2 flex items-center transition-[padding] duration-300 [[data-accordion-open='true']_&]:pt-20">
          {users.length > 0 && (
            <div className="-mr-2">
              <ProfileImageStack users={[...users, ...tags.map((x) => clientGroups.find((y) => y.id === x.id)!)]} size={ImageSize.XS} />
            </div>
          )}
        </div>
      </div>
    ),
    [clientGroups, clientUsers, disabled, formUsers, onUpdateUsersInternal, role, t, tags, users, warning],
  );

  return (
    <div className="my-4 rounded-[4px] border" data-accordion-open={open}>
      <Accordion
        active
        separationBorder="content"
        wrapperClassName="!m-0"
        bodyClassName="!pb-0 !pt-0 overflow-auto"
        title={accordionTitle}
        onChange={setOpen}
      >
        {users.length > 0 && (
          <div className="flex items-center pt-2 text-black">
            {enableOrdering && <div className="w-8 flex-shrink-0">{t('permissions-modal.group.order')}</div>}
            <div className="flex-grow">{t('permissions-modal.group.name')}</div>
            {requiredToggle && <div className="mr-6 flex-shrink-0">{t('permissions-modal.group.required')}</div>}
          </div>
        )}
        <div className="-mx-4 overflow-y-auto px-4">
          <Users
            users={users}
            onRemove={onUserRemoveInternal}
            role={role}
            tags={tags}
            requiredToggle={requiredToggle}
            onUserRequiredChanged={onUserRequiredChanged}
            enableOrdering={enableOrdering}
            onUsersReorder={onUsersReorder}
            searchPhrase={searchPhrase}
          />
          {users.length === 0 && <div className="py-2">{emptySlot()}</div>}

          <Groups tags={tags} onChange={onTagsChangeInternal} searchPhrase={searchPhrase} users={users} onGroupRemoved={onGroupRemoved} />
          {!footerSlot.isEmpty && (
            <Accordion
              title={t('permissions-modal.settings.heading')}
              active
              bodyClassName="!p-0 !m-0"
              titleClassName="!p-0 !m-0"
              wrapperClassName={`!p-0 !m-0 !-mx-3 !pl-4 !pr-3 border-t`}
              separationBorder="none"
            >
              <div className="-mx-4 px-4 empty:hidden">{footerSlot()}</div>
            </Accordion>
          )}
        </div>
      </Accordion>
    </div>
  );
});

export default ClientFormRoleGroup;

const Users: FC<{
  users: ClientFormUser[];
  onRemove?: (user: ClientFormUser) => void;
  role: ClientFormUserRoleValues;
  requiredToggle?: boolean;
  onUserRequiredChanged: (user: ClientFormUser, required: boolean) => void;
  enableOrdering?: boolean;
  onUsersReorder?: (users: ClientFormUser[]) => void;
  searchPhrase: string;
  tags: ClientFormTag[];
}> = (props) => {
  const { users, onRemove, role, requiredToggle, onUserRequiredChanged, enableOrdering, onUsersReorder, searchPhrase, tags } = props;
  const mounted = useRef(false);
  const { updateParentHeight } = useAccordionContext();

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
      return; // don't animate on mount
    }

    setTimeout(() => {
      updateParentHeight();
    }, 50);
  }, [updateParentHeight, users.length]);

  const dndSensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const itemIds = useMemo(() => users.filter((x) => x.requiresAction).map((x) => x.id), [users]);

  const handleDragEnd = useCallback(
    (evt: DragEndEvent) => {
      const { active, over } = evt;
      if (!active || !over || active.id === over.id) return;

      const oldIndex = users.findIndex((x) => x.id === active.id);
      const newIndex = users.findIndex((x) => x.id === over.id);
      const newOrder = arrayMove(users, oldIndex, newIndex).map((x, i) => ({ ...x, sortOrder: i }));

      onUsersReorder?.(newOrder);
    },
    [onUsersReorder, users],
  );

  return (
    <DndContext
      sensors={dndSensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
      modifiers={[restrictToVerticalAxis, restrictToFirstScrollableAncestor]}
    >
      <SortableContext items={itemIds} strategy={verticalListSortingStrategy}>
        {users.map((x) => (
          <UserRow
            key={role + ';' + x.id}
            user={x}
            tags={tags}
            onRemove={onRemove}
            requiredToggle={requiredToggle}
            onUserRequiredChanged={onUserRequiredChanged}
            enableOrdering={enableOrdering}
            searchPhrase={searchPhrase}
          />
        ))}
      </SortableContext>
    </DndContext>
  );
};

const UserRow: FC<{
  user: ClientFormUser;
  onRemove?: (user: ClientFormUser) => void;
  requiredToggle?: boolean;
  onUserRequiredChanged: (user: ClientFormUser, required: boolean) => void;
  enableOrdering?: boolean;
  searchPhrase: string;
  tags?: ClientFormTag[];
}> = (props) => {
  const { user, onRemove, requiredToggle, onUserRequiredChanged, enableOrdering, searchPhrase, tags } = props;

  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id: user.id, disabled: !user.requiresAction });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const userTags = useMemo(() => {
    return (
      user.tagIds
        ?.map((x) => tags?.find((t) => t.id === x))
        .filter(Boolean)
        .map((x) => LanguageUtils.getTranslation('name', x.translations))
        .reduce((acc, x) => acc + ' • ' + x, '')
        .substring(3) ?? ''
    );
  }, [tags, user.tagIds]);

  return (
    <div
      ref={setNodeRef}
      style={style}
      className={`hover:bg-gray-6 group/user-row relative -mx-2 my-1 flex items-center justify-between rounded-[4px] bg-white px-2 py-1 transition-colors ${isDragging ? 'z-10' : ''}`}
    >
      <div className="flex items-center">
        {enableOrdering && (
          <div className={`flex w-8 ${isDragging ? 'cursor-grabbing' : 'cursor-grab'} items-center`} {...attributes} {...listeners}>
            {user.requiresAction && (
              <>
                <span className="inline group-hover/user-row:hidden">{typeof user.sortOrder === 'number' ? user.sortOrder + 1 : null}</span>
                <span className="hidden group-hover/user-row:inline">
                  <DragHandleIcon className="h-4 w-4" />
                </span>
              </>
            )}
          </div>
        )}
        <UserListRenderer
          id={user.id}
          value={PeopleType.Member}
          text={user.fullName ?? ''}
          userImageId={user.userImageId}
          size={ImageSize.XS}
          textHighlight={searchPhrase}
          suffix={userTags}
        />
      </div>
      <div className="flex items-center">
        {requiredToggle && (
          <Checkbox slider sliderSize={SliderSize.S} value={!!user.requiresAction} onChange={(v) => onUserRequiredChanged(user, v)} />
        )}
        <XIcon
          className="h-5 w-5 text-black opacity-0 transition-opacity group-hover/user-row:opacity-100"
          onClick={() => {
            onRemove?.(user);
          }}
        />
      </div>
    </div>
  );
};

export const Groups: FC<{
  tags: ClientFormTag[];
  onChange?: (groups: ClientFormTag[]) => void;
  searchPhrase: string;
  users: { id: string }[];
  onGroupRemoved: (group: ClientFormTag) => void;
}> = (props) => {
  const { tags, onChange, searchPhrase, users, onGroupRemoved } = props;
  const mounted = useRef(false);
  const { updateParentHeight } = useAccordionContext();
  const { t } = useTranslation(['common']);

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
      return; // don't animate on mount
    }

    setTimeout(() => {
      updateParentHeight();
    }, 50);
  }, [updateParentHeight, tags.length]);

  const onRemove = useCallback(
    (tag: ClientFormTag) => {
      onChange?.(tags.filter((x) => x.id !== tag.id));
      onGroupRemoved(tag);
    },
    [onChange, onGroupRemoved, tags],
  );

  const onChangeInternal = useCallback(
    (group: ClientFormTag) => {
      onChange?.(tags.map((x) => (x.id === group.id ? group : x)));
    },
    [onChange, tags],
  );

  if (tags.length === 0) return null;
  return (
    <Accordion
      title={t('permissions-modal.tags.heading')}
      active
      bodyClassName="!p-0 !m-0 !-mx-3"
      titleClassName="!p-0 !m-0"
      wrapperClassName={`!p-0 !m-0 !-mx-3 !pl-4 !pr-3 border-t`}
      separationBorder="none"
    >
      {tags.map((x) => (
        <GroupRow key={x.id} tag={x} onRemove={() => onRemove(x)} searchPhrase={searchPhrase} onChange={onChangeInternal} users={users} />
      ))}
    </Accordion>
  );
};

const GroupRow: FC<{
  tag: ClientFormTag;
  onRemove?: (group: ClientFormTag) => void;
  searchPhrase: string;
  onChange?: (group: ClientFormTag) => void;
  users: { id: string }[];
}> = (props) => {
  const { tag: tag, onRemove, users, searchPhrase, onChange } = props;
  const { t } = useTranslation(['common']);

  const name = useMemo(() => {
    return LanguageUtils.getTranslation('name', tag?.translations ?? {});
  }, [tag?.translations]);

  const tooltipText = useMemo(() => {
    if (tag.synchronized) {
      return (
        <div>
          <div className="text-white">{t('permissions-modal.tags.sync-status.on.heading')}</div>
          <div>{t('permissions-modal.tags.sync-status.on.subheading')}</div>
        </div>
      );
    }

    const outOfSyncUsers = tag.users?.filter((x) => !users?.some((y) => x.id === y.id)) ?? [];
    return (
      <div>
        <div className="text-white">{t('permissions-modal.tags.sync-status.off.heading')}</div>
        {outOfSyncUsers.length > 0 && <div>{t('permissions-modal.tags.sync-status.off.subheading', { count: outOfSyncUsers.length })}</div>}
      </div>
    );
  }, [t, tag.synchronized, tag.users, users]);

  if (!!searchPhrase && !name.toLocaleLowerCase().includes(searchPhrase.toLocaleLowerCase())) return null;
  return (
    <div className="group/group-row hover:bg-gray-6 my-1 flex items-center justify-between rounded-[4px] bg-white px-2 py-1 transition-colors">
      <div className="flex items-center gap-2">
        <GroupAvatar users={(tag.users as User[]) ?? []} size={ImageSize.XS} />
        <div>{StringUtils.highlightText(name ?? '', searchPhrase)}</div>
        <div className="text-dpm-14 mt-1 font-light">{t(ClientTagTypeKeys[tag.type])}</div>
      </div>
      <div className="flex items-center gap-2">
        <Tooltip text={tooltipText}>
          {(tooltip) => (
            <span {...tooltip}>
              <SyncIcon
                className={`h-5 w-5 ${tag.synchronized ? 'text-accent-1' : 'text-gray2'}`}
                onClick={() => onChange?.({ ...tag, synchronized: !tag.synchronized })}
              />
            </span>
          )}
        </Tooltip>
        <XIcon
          className="h-5 w-5 flex-shrink-0 text-black opacity-0 transition-opacity group-hover/group-row:opacity-100"
          onClick={() => {
            onRemove?.(tag);
          }}
        />
      </div>
    </div>
  );
};
