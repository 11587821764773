import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import User from '../../models/User';
import {
  useFloating,
  offset,
  flip,
  shift,
  autoUpdate,
  useClick,
  useDismiss,
  useInteractions,
  useRole,
  FloatingFocusManager,
  size,
} from '@floating-ui/react';
import { PeopleType } from '../../models/Distribution';
import UserListRenderer from './UserListRenderer';
import PlusIcon from '../shared/icon/PlusIcon';
import Checkbox from '../shared/form-control/Checkbox';
import { mouseAndKeyboardCallbackProps } from '../../utils/ComponentUtils';
import { ImageSize } from './ProfileImageStack';
import { SearchInput } from '../shared/form-control/SearchInput';
import { InputStyle } from '../shared/form-control/Input';
import { useTranslation } from 'react-i18next';
import { ClientFormTag, ClientFormUser } from '../../models/ClientFormUser';
import Button, { ButtonSize, ButtonType } from '../shared/form-control/Button';
import { ClientUserGrouping } from '../../hooks/useFetchActiveClientGroups';
import LanguageUtils from '../../utils/LanguageUtils';
import { ClientTagTypeKeys } from '../../models/ClientTag';
import StringUtils from '../../utils/StringUtils';
import GroupAvatar from '../shared/profile-image/GroupAvatar';

type Props = {
  users: User[];
  clientGroups: ClientUserGrouping[];
  selectedUsers: (User & { tagIds?: string[] })[];
  selectedTags: ClientFormTag[];
  singleSelect?: boolean;
  onUsersChanged: (users: User[], groups: ClientFormTag[]) => void;

  // ClientFormUser properties
  formUsers?: ClientFormUser[];
  filterAssigned?: (value: ClientFormUser) => boolean;
};

const UsersSelect: FC<Props> = (props) => {
  const {
    users: propAllUsers,
    selectedUsers: propSelectedUsers,
    selectedTags: propSelectedTags,
    singleSelect,
    onUsersChanged,
    filterAssigned,
    formUsers,
    clientGroups: propAllClientGroups,
  } = props;
  const [open, setOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [maxHeight, setMaxHeight] = useState(500);

  const [selectedUsers, setSelectedUsers] = useState<(User & { tagIds?: string[] })[]>(propSelectedUsers);
  const [selectedGroups, setSelectedGroups] = useState(propSelectedTags);

  const selectedUserIds = useMemo(() => selectedUsers.map((x) => x.id), [selectedUsers]);
  const selectedGroupIds = useMemo(() => selectedGroups.map((x) => x.id), [selectedGroups]);

  const { t } = useTranslation(['common']);

  const { refs, floatingStyles, context } = useFloating({
    open: open,
    onOpenChange: setOpen,
    middleware: [
      offset(10),
      flip(),
      shift(),
      size({
        apply({ availableHeight }) {
          setMaxHeight(Math.min(availableHeight, 500));
        },
      }),
    ],
    whileElementsMounted: autoUpdate,
    strategy: 'fixed',
    placement: 'bottom-start',
  });

  const click = useClick(context);
  const dismiss = useDismiss(context);
  const role = useRole(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([click, dismiss, role]);

  useEffect(() => {
    if (open) return;

    setSearchValue('');
    setSelectedUsers(propSelectedUsers);
    setSelectedGroups(propSelectedTags);
  }, [open, propSelectedTags, propSelectedUsers]);

  const userOptions = useMemo(() => {
    const searchValueLower = searchValue.toLocaleLowerCase();

    let result = propAllUsers
      .map((x) => ({
        id: x.id,
        text: `${x.firstName} ${x.lastName}`,
        value: PeopleType.Member,
        userImageId: x.userImageId,
        user: x,
      }))
      .filter((x) => !searchValue || x.text.toLocaleLowerCase().includes(searchValueLower));

    if (filterAssigned && formUsers) {
      const usersToFilterOut = formUsers.filter(filterAssigned).map((x) => x.id);
      result = result.filter((x) => !usersToFilterOut.includes(x.id));
    }

    return result.sort((a, b) => a.text.localeCompare(b.text));
  }, [filterAssigned, formUsers, propAllUsers, searchValue]);

  const groupOptions = useMemo(() => {
    const searchValueLower = searchValue.toLocaleLowerCase();

    const result = propAllClientGroups
      ?.map((x) => ({
        id: x.id,
        name: LanguageUtils.getTranslation('name', x.translations),
        type: x.type,
        users: x.users,
        group: x,
      }))
      .filter((x) => !searchValue || x.name.toLocaleLowerCase().includes(searchValueLower));

    return result?.sort((a, b) => a.name.localeCompare(b.name));
  }, [propAllClientGroups, searchValue]);

  const selectedUsersChanged = useCallback(
    (user: User, value: boolean) => {
      if (singleSelect) {
        onUsersChanged([user], []);
        return;
      }

      if (value) {
        setSelectedUsers((prev) => {
          return [...prev, { ...user, addedAsIndividual: true }];
        });
      } else {
        setSelectedUsers((prev) => prev.filter((x) => x.id !== user.id));
        setSelectedGroups((prev) => prev.map((x) => (x.users?.some((u) => u.id === user.id) ? { ...x, synchronized: false } : x)));
      }
    },
    [onUsersChanged, singleSelect],
  );

  const selectedGroupsChanges = useCallback((group: ClientUserGrouping, value: boolean) => {
    if (value) {
      const groupUsers = group.users;
      setSelectedUsers((prev) => {
        const result = prev.map((x) => {
          if (!groupUsers.some((u) => u.id === x.id)) {
            return x;
          }

          return {
            ...x,
            tagIds: [...(x.tagIds || []), group.id],
          };
        });

        const toAdd = groupUsers
          .filter((x) => !result.some((r) => r.id === x.id))
          .map((x) => ({ ...x, tagIds: [group.id], addedAsIndividual: false }));

        return [...result, ...toAdd];
      });
      setSelectedGroups((prev) => [
        ...prev,
        {
          id: group.id,
          type: group.type,
          translations: group.translations,
          synchronized: true, // on add, default to sync true
          users: groupUsers,
          formSectionId: group.formSectionId,
        },
      ]);
    } else {
      setSelectedGroups((prev) => prev.filter((x) => x.id !== group.id));
    }
  }, []);

  const onSave = useCallback(() => {
    onUsersChanged(selectedUsers, selectedGroups);
    setOpen(false);
  }, [onUsersChanged, selectedGroups, selectedUsers]);

  return (
    <div {...mouseAndKeyboardCallbackProps((e) => e.stopPropagation())}>
      <span ref={refs.setReference} {...getReferenceProps()}>
        <PlusIcon className="text-gray-1 hover:bg-accent-1 bg-gray-6 h-6 w-6 cursor-pointer rounded-full p-[5px] transition-colors hover:text-white" />
      </span>

      {open && (
        <FloatingFocusManager context={context} modal={false}>
          <div
            ref={refs.setFloating}
            style={{ ...floatingStyles, zIndex: 9000, maxHeight }}
            {...getFloatingProps()}
            className="min-w-[320px] max-w-[500px] rounded-[5px] bg-white p-2 shadow-md"
          >
            <div className="bg-white p-2">
              <SearchInput
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                style={InputStyle.MINIMAL}
                placeholder={t('add-or-invite-modal.add.search')}
              />
            </div>

            <div className="overflow-y-auto" style={{ maxHeight: maxHeight - 100 }}>
              <div>
                <div className="text-dpm-14 -mb-2 mt-4 px-2 font-light text-black">{t('add-or-invite-modal.users')}</div>
                {userOptions.map((x) => (
                  <div
                    key={x.id}
                    className="hover:bg-gray-6 my-1 flex cursor-pointer items-center gap-2 rounded-[5px] px-2 transition-colors"
                    {...mouseAndKeyboardCallbackProps(() => selectedUsersChanged(x.user, !selectedUserIds.includes(x.id)))}
                  >
                    {!singleSelect && <Checkbox value={selectedUserIds.includes(x.id)} onChange={(value) => selectedUsersChanged(x.user, value)} />}
                    <UserListRenderer {...x} size={ImageSize.XS} textHighlight={searchValue} />
                  </div>
                ))}
              </div>

              {!singleSelect && (
                <div>
                  <div className="text-dpm-14 -mb-2 mt-4 px-2 font-light text-black">{t('add-or-invite-modal.groups')}</div>
                  {groupOptions?.map((x) => (
                    <div
                      key={x.id}
                      className="hover:bg-gray-6 my-1 flex cursor-pointer items-center gap-2 rounded-[5px] px-2 transition-colors"
                      {...mouseAndKeyboardCallbackProps(() => selectedGroupsChanges(x.group, !selectedGroupIds.includes(x.id)))}
                    >
                      <Checkbox value={selectedGroupIds.includes(x.id)} onChange={(value) => selectedGroupsChanges(x.group, value)} />
                      <GroupAvatar users={x.users} size={ImageSize.XS} />
                      <span>{StringUtils.highlightText(x.name, searchValue)}</span>
                      <span className="text-dpm-14 font-light">{t(ClientTagTypeKeys[x.type])}</span>
                    </div>
                  ))}
                </div>
              )}
            </div>

            {!singleSelect && (
              <div className="flex items-center justify-end gap-2 bg-white py-2">
                <Button size={ButtonSize.S} type={ButtonType.SECONDARY} onClick={() => setOpen(false)}>
                  {t('modals.cancel')}
                </Button>
                <Button size={ButtonSize.S} onClick={onSave}>
                  {t('modals.save')}
                </Button>
              </div>
            )}
          </div>
        </FloatingFocusManager>
      )}
    </div>
  );
};

export default UsersSelect;
